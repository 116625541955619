.skills-container {
  display: flex;
  right: 20px; /* Pada tampilan desktop, di sebelah kanan */
  top: 20px;
  z-index: 100;
}

.skill-box {
  background-color: #007bff; /* Warna kotak (biru) */
  color: white;
  padding: 10px 20px; /* Padding untuk kotak */
  border-radius: 20px; /* Membuat rounded box */
  margin-bottom: 10px; /* Jarak antar kotak */
  font-size: 16px;
  white-space: nowrap; /* Kotak menyesuaikan panjang teks */
  transition: background-color 0.3s ease; /* Animasi transisi */
  cursor: pointer; /* Ganti kursor menjadi pointer */
}

.skill-box:hover {
  background-color: white; /* Latar belakang berubah menjadi putih */
  color: #007bff; /* Ubah warna teks menjadi biru */
}

@media only screen and (max-width: 768px) {
  .skills-container {
    position: static; /* Letakkan di posisi normal (di layar penuh) */
    width: 100%; /* Lebar penuh */
    margin: 20px auto; /* Beri margin agar kotak terpusat */
    align-items: center; /* Posisikan teks di tengah */
  }
}


.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Google Sans Regular";
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

.SkillsHeading {
  padding-top:40px;
}

.skill-icon-container {
  position: relative;
  cursor: pointer;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.skill-badge {
  position: absolute;
  bottom: -30px; /* Atur jarak badge dari ikon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff; /* Warna latar belakang badge */
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 15px;
  opacity: 0; /* Tidak terlihat sampai hover */
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Nonaktifkan interaksi pada badge */
}

.icon-wrapper:hover .skill-badge {
  opacity: 1; /* Munculkan badge saat hover */
}

.icon-wrapper {
  transition: transform 0.3s ease;  /* Efek animasi saat hover */
}

.icon-wrapper:hover {
  transform: scale(1.2);  /* Memperbesar icon saat hover */
}

.skills-section {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.skills-column {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  min-height: 500px; 
}

@media (max-width: 768px) {
  .skills-section {
    flex-direction: column;
  }
}
