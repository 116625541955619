  .home-right{
    height: 100%;
  }

  .home-right-main{
    /* height: 100%; */
    text-align: center;
  }
  .home-right-main-col{
    margin: auto;    
    display: block;
  }

  .home-right-main-img{
      height: 65vh;
      margin: auto;    
      display: block;
  }

  .home-right-footer{
     width: 100%;
     height: fit-content;
  }
