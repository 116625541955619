.Loading-header {
    background-color: #000000;
    background-image: url('../../assets/img/bayoe.id_logo.png');
    background-size:38%;
    background-position: center;
    background-repeat: no-repeat;
    /* background-size:contain; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
