.timeline {
    position: relative;
    padding-left: 20px;
    border-left: 2px solid #ddd;
  }
  
  .timeline-item {
    position: relative;
    padding: 20px 0;
  }
  
  .timeline-dot {
    position: absolute;
    left: -10px;
    top: 20px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #007bff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline-icon {
    width: 15px;
    height: 15px;
  }
  
  .timeline-content {
    margin-left: 30px;
  }
  
  @media (max-width: 768px) {
    .timeline {
      border-left: none;
      padding-left: 0;
    }
    .timeline-item {
      text-align: center;
    }
    .timeline-dot {
      left: 50%;
      transform: translateX(-50%);
    }
    .timeline-content {
      margin-left: 0;
    }
  }
  
.vertical-timeline-element-date {
  color: var(--tertiary-color);
}