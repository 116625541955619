@import url('https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.Contact-form{
    text-align: center;
    padding-bottom: 8%;
  }

.contact-form-header{
    padding: 1%;
    /* font-family: 'Raleway', sans-serif; */
    font-size: 4rem;
    font-family: 'Sulphur Point', sans-serif;
   }

.contact_input{
   padding-left: 10%;
   padding-right: 10%;
  }

.contact_input_text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.5rem;
    border-radius: 5%;
    text-align: center;
    background: transparent;
    color: white;
    min-height: 6.5vh;
  }
   
.contact_success_modal_body{
    text-align: center;
    background: #090909;
    color: aliceblue;
   }
   
.contact_success_modal_img{
     height: 15vh;
     padding-bottom: 3%;
   }
 
.contact-email-text-btn:hover{
      background: #666666;
      color: aliceblue;
  }
 
.contact_input input::placeholder {
  color: #888;
  opacity: 1;
}

.contact_input textarea::placeholder {
  color: #888;
  opacity: 1;
}

/* ContactForm.css */
/* .custom-radio-group {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
} */

.custom-radio .form-check-input {
  appearance: none; /* Hilangkan tampilan default */
  /* background-color: #ddd; */
  /* border: 2px solid #888; */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.custom-radio .form-check-input:checked {
  background-color: #007bff; /* Warna ketika dipilih */
  border-color: #007bff;
}

.custom-radio .form-check-input:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.75rem;
  /* background-color: white; */
  border-radius: 50%;
}

.custom-radio .form-check-label {
  margin-left: 1rem;
  font-size: 1rem;
  /* color: #333; */
}
