.img-bayoe {
  background-image: url("../../assets/img/profil/bayoe.jpg");
}
.img-arif {
  background-image: url("../../assets/img/profil/arif.jpg");
}
.img-nowo {
  background-image: url("../../assets/img/bayoe.id_logo.png");
}
.img-lilik {
  background-image: url("../../assets/img/profil/lilik.jpg");
}
.deneme {
  width: 380px;
  height: 380px;
  background-size: cover;
  box-shadow: 0 5px 5px 5px rgba(13, 110, 253, 0.2);
  animation: animate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
  position: relative;
  background-repeat: no-repeat;
}
.badges-container-bayoe{
  position: absolute;
  bottom: -125px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px; 
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.badges-container-arif{
  position: absolute;
  bottom: -125px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px; 
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.badges-container-nowo{
  position: absolute;
  bottom: -125px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px; 
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.badges-container {
  position: absolute;
  bottom: -125px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px; 
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
@keyframes animate {
  0%,
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
}

@media (max-width: 768px) {
  .deneme {
    max-height: 65%;
    max-width: 45%;
    margin-bottom: 50% !important;
    background-size:100%;
    background-position: center;
    height: 160px;
  }
  .badge-item {
    font-size: 0.75rem;
    padding: 0.3rem 0.5rem;
  }
  .badges-container-bayoe{
    bottom: -180px;
  }
  .badges-container-arif{
    bottom: -180px;
  }
  .badges-container-nowo{
    bottom: -180px;
  }
  
  .badges-container{
    bottom: -180px;
  }
}

.badge-item {
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
}