.zindex1 {
    z-index: 0;
}
.App-header {
    background-color:#090909;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-main {
    min-height: 95vh;
}

.App-skill {
  text-align: center;
}

.App-footer {
    min-height: 5vh;
}

.particle{
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.page-content {
    color: white;
}