.Contact-header {
  background-color:#090909;
  color: white;
  z-index: 1; /* Beri z-index yang lebih tinggi dari partikel */
  position: relative; /* Pastikan elemen memiliki posisi relatif atau absolut */
  border-radius: 35px;
}

.Hamburger-menu{
  min-height: 7vh; 
}

.Contact-main {
  /* min-height: 88vh;  */
  /* padding-left: 80px; */
 }

.Contact-left{
  margin: auto;    
  display: block;
}

.Contact-right{
  margin: auto;    
  display: block;
}

.Contact-footer {
  min-height: 5vh; 
}  

