@import url('https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@font-face {
    font-family: 'Gliker-Bold';
    src: url('../../assets/fonts/Gliker-BoldExpanded.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
.home-left{
    height: 100%;
 }

.home-left-main{
    height: 100%;
    text-align: center;
}

.home-left-main-col{
    margin: auto;    
    display: block;
    margin-bottom: 0px;
}

@media screen and (max-width: 600px) and (min-width: 312px) {
    .home-left-main-col{
        margin-top: 50%;
    }
}

@media screen and (max-width: 980px) and (min-width: 600px) {
    .home-left-main-col{
        margin-top: 50%;
    }
}

@media only screen and (min-width: 1100) and (max-width: 1124px) and (orientation:landscape) {
    .home-left-main-col{
        margin-top: 20%;
    }
}
.first-line{
    font-size: 4.5rem;
    font-family: 'Sulphur Point', sans-serif;
}

.second_line{
    font-size: 3rem;
    font-family: 'Gliker-Bold', sans-serif,'Sulphur Point', 'Julius Sans One';
}

.typical{
    font-size: 1.6rem;
    font-family: 'Sulphur Point', sans-serif;
}

/* .home-left-aboutme-main{
    position: relative;
    z-index: -1;
} */

.home-left-aboutme{
   color: aliceblue;
}

/* .home-left-aboutme:hover{
    color: black;
 } */

.home-left-aboutmme-btn:hover{
     background: black;
 }

 .home-left-aboutmme-btn a:hover{
    color: white;
}
