:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}

body, html {
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}
  