.fb{
  margin: 2%;
  color: white;
}

.fb:hover{
  color: #386ddd;
}

.git{
  margin: 2%;
  color: white;
}

.git:hover{
  color: #16fa01;
}

.twitter{
  margin: 2%;
  color: white;
}

.twitter:hover{
  color: #00acee;
}

.wa {
  margin: 2%;
  color: white;
  font-size: xx-large;
}
.wa:hover{
  color: #12da00;
}
.insta{
  margin: 2%;
  color: white;
  font-size: xx-large;
}

.insta:hover{
  color: #c1558b;
}

.linkedin{
  margin: 2%;
  color: white;
}

.linkedin:hover{
  color: #0e76a8;
}
.social-icons a {
  margin: 10px;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .social-icons a {
    font-size: 1.2rem;
    margin: 5px;
  }
}