@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.Contact-text{
  text-align: center;
 }

.p-heading1{
  font-size: 4rem;
  font-family: 'Sulphur Point', sans-serif;
}

.p-heading2{
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Roboto Mono', monospace;
}

.contact-left-footer{
  width: 100%;
  font-size: 1.7rem;
}

.layanan-kami {
  margin: 20px 0;
  padding: 20px;
  background-color: white; /* Warna latar belakang */
  border-radius: 8px;
  color: black;
}

.social-media-links {
  display: flex;
  gap: 15px; /* Jarak antar ikon sosial media */
}

.social-icon {
  font-size: 24px; /* Ukuran ikon sosial media */
  color: #333; /* Warna ikon */
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff; /* Warna saat hover */
}

.reposisi-card {
  padding-left: 5%;
  padding-right: 5%;
}

.service-card {
  background-color: #fff; /* Warna latar belakang kartu */
  border: 1px solid #ddd; /* Batas kartu */
  border-radius: 10px; /* Sudut melengkung */
  padding: 20px; /* Ruang dalam */
  text-align: center; /* Rata tengah */
  margin: 10px; /* Jarak antar kartu */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Bayangan */
}

.services-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #f8f9fa; /* Warna latar belakang keseluruhan */
  border-radius: 8px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

.services-list {
  display: flex;
  flex-direction: column; /* Menampilkan secara vertikal */
  gap: 20px; /* Jarak antar item */
}

.social-media-card {
  margin: 1rem auto; /* Margin atas dan bawah 1rem, margin kiri dan kanan otomatis */
  max-width: 400px; /* Sesuaikan lebar maksimum card */
  padding: 1rem;
  background-color: #000000; /* Warna latar belakang yang lebih terang */
  border-radius: 10px;
}

.social-media-card .card-body {
  padding: 0px;
}

.contact-hubungi-kami-text-btn {
  background: #25D366;
  color: aliceblue;
}

.contact-hubungi-kami-text-btn:hover {
  background: #000000;
  color: #25D366;
}

.services-section {
  text-align: center;
  padding: 2rem 1rem;
}

.services-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

/* Styling for each service item */
.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 300px;
  margin: 0.5rem;
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #007bff;
}

/* Title styling */
.service-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #007bff;
}

/* Description styling */
.service-description {
  font-size: 1rem;
  color: #666;
  margin-top: 0.25rem;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 576px) {
  .services-list {
    gap: 1.5rem;
  }

  .service-item {
    padding: 1rem;
  }

  .service-title {
    font-size: 1.2rem;
  }

  .service-description {
    font-size: 0.9rem;
  }
  .reposisi-card {
    padding-left: unset;
    padding-right: unset;
  }
}

@media (min-width: 768px) {
  /* Display items in a row on desktop */
  .services-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .service-item {
    max-width: 250px;
    flex: 1 1 30%; /* Adjust card width to fit within row */
  }
}